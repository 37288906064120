.slick-prev:before, 
.slick-next:before {
  line-height: 0;
  justify-content: space-evenly;
  font-size: 18px; /* Default font size for small screens */
  color: #c9b38c;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (min-width: 640px) { /* For small screens (sm) */
  .slick-prev:before, 
  .slick-next:before {
    font-size: 20px;
  }
}

@media (min-width: 768px) { /* For medium screens (md) */
  .slick-prev:before, 
  .slick-next:before {
    font-size: 24px;
  }
}

@media (min-width: 1024px) { /* For large screens (lg) */
  .slick-prev:before, 
  .slick-next:before {
    font-size: 30px;
  }
}
