

.entry-detassls:before {
  content: "";
  background: #687693;
  width: 30px;
  height: 4px;
  position: absolute;
  left: 2px;
  bottom: 44px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
}