@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap');

/* ===== Scrollbar CSS ===== */
  /* Firefox */

  @font-face {
    font-family: "Muli";
    src: url("../src/components/images/font/Muli.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
  
  * {
    scrollbar-width: thin;
    scrollbar-color: #262b3e #ffffff;
    font-family: "Roboto Slab", Muli, sans-serif;
  }
/* hello */
  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 21px;
    
    
  }
  ::selection {

    color: white;
    background: #c9b38c;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 10px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #262b3e;
    border-radius: 21px;
    border: 3px solid #ffcccc;
  }
  .jodit-workplace {
    height: 762px;
}

/* src/App.css */
/* Add general styles for your app */

/* src/components/Loader.css */
/* Add styles for the loader component */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.5rem;
  color: #4a5568; /* Tailwind gray-700 */
}

.content {
  text-align: center;
  font-size: 1.5rem;
}



.animate-shadow-pulse {
  animation: shadowPulse 2s infinite;
}

@keyframes shadowPulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(72, 187, 120, 0.5);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 15px rgba(72, 187, 120, 0);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(72, 187, 120, 0);
  }
}


